import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Headline5 from '../Typography/Heading/Headline5';
import Body2 from '../Typography/Body/Body2';
import Overline from '../Typography/Overline';
import { CardOverlineSuffix } from '../Card';
import Labels from '../Card/Labels';
import breakpoints from '../Theme/breakpoints';
import truncateLines from '../TruncateLines';
import { TypographyProperties } from '../Typography/Typography';
import Skeleton from '../SkeletonLoader/Skeleton';
import DescriptionSkeleton from '../SkeletonLoader/Default';
import { minimalRoundedCorners } from '../RoundedCorners';

const newsListItemPropertyTypes = {
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.string,
  firstItemPaddingTop: PropTypes.string,
  lastItemPadding: PropTypes.string,
  noBorderFirstItem: PropTypes.bool,
};

type NewsListItemProperties = InferProps<typeof newsListItemPropertyTypes>;

const NewsListItem = styled.li<NewsListItemProperties>`
  padding: 24px 0;
  ${(
    { borderTop = true },
  ) => borderTop && 'border-top: 1px solid var(--grey-light);'}


  @media (min-width: ${breakpoints.lg}px) {
    &:first-of-type {
      ${({ noBorderFirstItem }) => noBorderFirstItem && 'border-top: none;'}
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 16px 0;

    &:first-of-type {
      padding-top: ${(properties) => properties.firstItemPaddingTop};
    }
  }
`;

NewsListItem.propTypes = newsListItemPropertyTypes;

NewsListItem.defaultProps = {
  noBorderFirstItem: false,
};

const NewsListDivider = styled.div`
  border-top: 1px solid var(--grey-light);
`;

const NewsEndOfListDivider = styled.div`
  @media (min-width: ${breakpoints.md}px) {
    border-top: 1px solid var(--grey-light);
  }
`;

const ListItemTextContainer = styled.div`
  &&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: auto;
  }
`;

interface ListItemTitleContainerProperties {
  restrictWidth?: boolean;
}

const ListItemTitleContainer = styled.div`
  word-wrap: fit-content;
  ${({ restrictWidth } : ListItemTitleContainerProperties) => (
    restrictWidth && 'max-width: calc(100% - 96px);'
  )}
`;

const StyledHeadLine5 = styled(Headline5)`
  && {
    @media (min-width: ${breakpoints.md}px) {
      font-size: var(--size-h6);
    }
  }
`;

const newsListItemContentPropertyTypes = {
  title: PropTypes.string,
  imageRight: PropTypes.node,
  category: PropTypes.string,
  tag: PropTypes.string,
};

type NewsListItemContentProperties = InferProps<
  typeof newsListItemContentPropertyTypes
> & Pick<TypographyProperties, 'tag'>;

const NewsListItemContent = ({
  title,
  imageRight,
  category,
  tag = 'span',
}: NewsListItemContentProperties) => (
  <ListItemTextContainer>
    <ListItemTitleContainer
      restrictWidth={Boolean(imageRight)}
    >
      {category && <Overline>{category}</Overline>}
      {title && <Headline5 tag={tag}>{title}</Headline5>}
    </ListItemTitleContainer>
    {imageRight}
  </ListItemTextContainer>
);

NewsListItemContent.propTypes = newsListItemContentPropertyTypes;

const categoryNewsListItemContentPropertyTypes = {
  title: PropTypes.string,
  tag: PropTypes.string,
};

type CategoryNewsListItemContentProperties = InferProps<
  typeof categoryNewsListItemContentPropertyTypes
> & Pick<TypographyProperties, 'tag'>;

const CategoryNewsListItemContent = ({
  title,
  tag = 'span',
}: CategoryNewsListItemContentProperties) => (
  <ListItemTextContainer
    className="fu-list-item-content"
  >
    {title && <StyledHeadLine5 tag={tag}>{title}</StyledHeadLine5>}
  </ListItemTextContainer>
);

// eslint-disable-next-line max-len
CategoryNewsListItemContent.propTypes = categoryNewsListItemContentPropertyTypes;

const DetailsContainer = styled.div`
  padding: 4px 0 0;
  @media (min-width: ${breakpoints.sm}px) {
    padding: 0;
  }
`;

const CategoryNewsListItem = styled.li`
  padding: 16px 0;

  @media (min-width: ${breakpoints.md}px) {
    padding: 12px 8px;
  }
`;

const SearchListItemWrapper = styled.li`
  padding: 24px 0;
  border-top: 1px solid var(--grey-light);
  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  @media (min-width: ${breakpoints.sm}px) {
    padding: 24px 16px;
  }
`;

const NewsFeedListItemWrapper = styled.li`
  padding: 16px 0;
  border-top: 1px solid var(--grey-light);
  clear: both;
  &:first-of-type {
    border-top: none;
  }
`;

const SearchResultsItemContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
`;

const NewsListSummary = styled(Body2)`
  && {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
  }

  ${truncateLines};

  @media (min-width: ${breakpoints.md}px) {
    display: block;
    -webkit-line-clamp: initial;
    overflow: visible;
    text-overflow: initial;
  }
`;

const searchResultsItemPropertyTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
  }),
  date: PropTypes.node,
  category: PropTypes.string,
  isPressRelease: PropTypes.bool,
  isPartnerContent: PropTypes.bool,
  image: PropTypes.node,
};

type SearchResultsItemProperties = InferProps<
  typeof searchResultsItemPropertyTypes
>;

const SearchResultsItem = ({
  article,
  date,
  category,
  isPressRelease,
  isPartnerContent,
  image,
}: SearchResultsItemProperties) => {
  const { title, summary } = { ...article };

  return (
    <SearchResultsItemContainer>
      {image}
      <div>
        <Headline5 tag="h2">
          {title}
        </Headline5>
        <Body2>
          {date}
          { category && `
          ${category}${isPressRelease ? ' Press Release' : ''}
          ${isPartnerContent ? ' - Partner Content' : ''}
          `}
        </Body2>
        <NewsListSummary>
          {summary}
        </NewsListSummary>
      </div>
    </SearchResultsItemContainer>
  );
};

SearchResultsItem.propTypes = searchResultsItemPropertyTypes;

const featuredMarking = css`
  ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -12px;
    border-left: 3px solid var(--primary-color);
    ${minimalRoundedCorners};
  }
`;

const NewsFeedItemWrapper = styled.div<{ featured?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
  ${({ featured }) => featured && featuredMarking}
  @media (min-width: ${breakpoints.sm}px) {
    display: block;
  }
  @media (min-width: ${breakpoints.md}px) {
    min-height: 134px;
  }
`;

const ImageContainer = styled.div`
  width: 201px;
  max-width: 36vw;
  max-height: 24vw;
  float: right;
  padding: 0 0 0 8px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0 0 0 8px;
    height: 134px;
  }
`;

const ArticleTitle = styled(Headline5)`
  && {
    flex-grow: 0;
    flex-basis: min-content;
    line-height: 1.5rem;
    padding: 0 0 4px 0;
    max-width: calc(64vw - 40px);

    @media (min-width: ${breakpoints.md}px) {
      max-width: none;
    }
  }
`;

const ArticleTeaser = styled(Body2)`
  && {
    flex-grow: 1;
    font-family: var(--font-article);

    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;

    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ArticleDetails = styled(Body2)`
  display: flex;
  align-items: baseline;
  font-family: var(--font-article);
  flex-grow: 0;
  flex-basis: 16px;
  padding: 2px;
  line-height: 1rem;
  @media (min-width: ${breakpoints.sm}px) {
    align-items: center;
    padding: 1px 1px 0;
    line-height: 1.25rem;
  }
`;

const ArticleCategory = styled(Overline)`
  padding: 0 8px 0 0;
  display: flex;
`;

const ArticleDate = styled.span`
  font-family: var(--font-article);
  color: var(--black-disabled);
  font-size: 14px;
`;

const ArticleViews = styled.span`
  display: inline-block;
  font-family: var(--font-primary);
  font-weight: bold;
  line-height: 1.8rem;
  color: var(--grey-dark);
`;

const newsFeedItemPropertyTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    featured: PropTypes.bool,
    pageviews: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
  date: PropTypes.node,
  category: PropTypes.string,
  image: PropTypes.node,
  isPressRelease: PropTypes.bool,
  isPartnerContent: PropTypes.bool,
  showLoader: PropTypes.bool,
};

type NewsFeedItemProperties = InferProps<
  typeof newsFeedItemPropertyTypes
>;

const NewsFeedItem = ({
  article,
  date,
  category,
  image,
  isPressRelease,
  isPartnerContent,
  showLoader,
}: NewsFeedItemProperties) => {
  const {
    title,
    summary,
    featured,
    pageviews,
    labels,
  } = { ...article };

  return (
    <NewsFeedItemWrapper featured={featured ?? undefined}>
      <div>
        <ImageContainer>
          { showLoader ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />)
            : image }
        </ImageContainer>
        {showLoader ? (
          <Skeleton variant="rectangular" width="50%">
            <ArticleTitle tag="h2">&nbsp;</ArticleTitle>
          </Skeleton>
        ) : <ArticleTitle tag="h2">{title}</ArticleTitle>}
        <ArticleTeaser>
          {showLoader ? <DescriptionSkeleton /> : summary}
        </ArticleTeaser>
      </div>
      <DetailsContainer>
        <ArticleDetails>
          <ArticleCategory>
            {showLoader ? (
              <Skeleton variant="rectangular" width="100px">&nbsp;</Skeleton>
            ) : category}
            {showLoader ? (
              <CardOverlineSuffix>
                <Skeleton variant="rectangular" width="100%" />
              </CardOverlineSuffix>
            ) : isPressRelease && (
            <CardOverlineSuffix>Press Release</CardOverlineSuffix>
            )}
            {showLoader ? (
              <CardOverlineSuffix>
                <Skeleton variant="rectangular" width="100%" />
              </CardOverlineSuffix>
            ) : isPartnerContent && (
            <CardOverlineSuffix>Partner Content</CardOverlineSuffix>
            )}
            {showLoader ? (
              <Skeleton variant="rectangular" width="100%">&nbsp;</Skeleton>)
              : <Labels labels={labels} />}
          </ArticleCategory>
          {showLoader ? (
            <Skeleton variant="rectangular" width="100%">
              <ArticleDate>&nbsp;</ArticleDate>
            </Skeleton>
          ) : <ArticleDate>{date}</ArticleDate>}
          {showLoader ? (
            <Skeleton variant="rectangular" width="100%">
              <ArticleCategory>
                <Labels featured={featured} />
              </ArticleCategory>
            </Skeleton>
          ) : <ArticleCategory><Labels featured={featured} /></ArticleCategory>}
        </ArticleDetails>
        {pageviews && (
          <ArticleDetails>
            <ArticleViews>{`${pageviews} Views`}</ArticleViews>
          </ArticleDetails>
        )}
      </DetailsContainer>
    </NewsFeedItemWrapper>
  );
};

NewsFeedItem.propTypes = newsFeedItemPropertyTypes;

export {
  NewsListItemContent, NewsListItem, CategoryNewsListItemContent,
  CategoryNewsListItem, NewsListDivider, NewsEndOfListDivider,
  SearchListItemWrapper, SearchResultsItem,
  NewsFeedListItemWrapper, NewsFeedItem,
};
